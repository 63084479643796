export
default {
        common:
        {
            langYn:
            'Tiếng Việt',
            langEn: 'English',
            langJa: '日本語',
            langFt: '繁體中文',
            langKr: '한국인'

        },

        home: {
            h1: "數字加密貨幣銀行",
            h1B: "數字加密貨幣銀行",
            h2: "邀請好友",
            h3: "銀行賬號",
            h4: "參與儲蓄",
            h5: "精選理財",
            h6: "用戶池",
            h7: "賬戶",
            h8: "賬戶",
            h9: "有效節點",
            h10: "參加者",
            h11: "收益",
            h12: "盈利記錄",
            h13: "地址",
            h14: "數字",
            h15: "常見問題",
            h16: "我如何加入",
            h17: "尊敬的用戶，如需參與USDT錢包儲蓄，需要在遵守平台用戶守則的前提下，嚴格執行平台的決定，接受平台的風險部門的監管，成功開設賬戶後會自動獲得存款權限，歡迎你的加入，謝謝。",
            h18: "我如何取款？",
            h19: " 您每天產生的收益會自動存入你的賬戶餘額，USDT取款成功後會自動添加到你的USDT錢包賬戶，越南盾取款請聯繫在線客服中心辦理，感謝你的參與支持。",
            h20: "如何計算收入？",
            h21: " 當您加入成功後，儲蓄合約開始啟動，開始計算收益。",
            h22: "儲蓄收益從何而來？",
            h23: "用戶一旦參與儲蓄，即儲蓄合約生效，每天用戶可獲得四次利息結算，時間分別是0時，6時，12時，18時。儲蓄合約生效後，用戶資金開啟託管模式，資金安全由平台負責。為保證用戶賬戶資金安全，請嚴格遵守平台規則，遇到問題及時諮詢在線客服中心，謝謝。",
            h24: "獎金是多少？",
            h25: "您可以與您的朋友分享我的平台。如果他加入，你可以獲得他首次存款金額的20%作為推薦福利金，還可以一直獲得他收入的10%作為推薦佣金。您的分享公鏈在首頁右上角。",
            h26: "賬戶",
            h27: "總收入",
            h28: "今日收益",
            h29: "理財收益",
            h30: "提現",
            h31: "記錄",
            h32: "可用餘額",
            h33: "提款金額",
            h34: "請輸入提款金額",
            h35: "全部",
            h36: "提取",
            h37: "審計報告",
            h38: "我們有一份安全的審計報告",
            h39: "夥伴",
            h40: "我們的合作夥伴",
            h41: "查看銀行卡號碼",
            h42: "上一頁",
            h43: "下一頁",
            h44: "已經是第一頁",
            h45: "請輸入提幣金額",
            h46: "您的餘額不足",
            h47: "已經是最後一頁了",
            h48: "收入",
            h49: "支出",
            h50: '操作過度，請稍後再試！',
            h51: '審計報告',
            h52: '存幣生息',
            h53: '訂單交易',
            h54: '註冊獎勵',
            h55: '邀請獎勵',
            h56: '提現',
            h57: '充值',
            h58: '上分',
            h59: '下分',
            h60: '使用加密貨幣匯款贈送USDT',
            h61: '感謝眾多用戶對MetaMask Bank的支持為了讓使用者更方便的保存使用加密貨幣（USDT ETH BTC）發送和保存時可獲得儲蓄獎金 存滿1000 USDT以上，額外獲得30 USDT獎勵 存滿 5000 USDT 即可獲贈 200 USDT 獎勵 每次儲蓄超過 10,000 USDT 即可獲得 5% 的儲蓄獎勵 （使用加密貨幣錢包提款可獲得同等福利）'
        },
        bankcard: {
            b1: "銀行賬戶",
            b2: "銀行名稱",
            b3: "請輸入銀行名稱",
            b4: "銀行分行",
            b5: "請輸入銀行分行",
            b6: "分行號",
            b7: "請輸入分行號",
            b8: "賬號",
            b9: "請輸入賬號",
            b10: "賬戶名(平假名)",
            b11: "請輸入賬戶名",
            b12: "賬戶名稱",
            b13: "請輸入賬戶名稱",
            b14: "綁定",

        },
        usdt: {
            u1: '我的訂單',
            u2: '購買',
            u3: '請選擇合約時間:',
            u4: '購買金額:',
            u5: '請輸入購買金額',
            u6: '買多',
            u7: '買少',
            u8: '操作成功',
            u9:'最少'

        },
        order: {
            o0: '我的訂單',
            o1: '訂單編號',
            o2: '訂單類型',
            o3: '買入金額',
            o4: '賣出金額',
            o5: '支付金額',
            o6: '合約時間',
            o7: '訂單狀態',
            o8: '結算狀態',
            o9: '結算時間',
            o10: '下單時間',
            o11: '買多',
            o12: '買少',
            o13: '已賣出',
            o14: '盈利',
            o15: '虧損',
            o16: '已支付',
            o17: '持平'
        },
        recharge: {
            r1: '儲值',
            r2: '儲值金額',
            r3: '儲值種類',
            r4: '請選擇',
            r5: '充幣地址',
            r6: '轉帳交易訂單編號',
            r7: '轉帳',
            r8: '正在審核',
            r9: '輸入有誤',
            r10:'请选择支付方式',
            r11:"最低充值金额为",
            r12:"最大充值金额为",
            r13: "請輸入金額",
            r14: "上傳付款截圖",
            r15: "請上傳付款截圖",
            r16: "提交成功",
        },
        rechargeDetails: {
            r1: "儲值記錄",
            r2: "儲值",
            r3: "正在審核",
            r4: "儲值成功",
            r5: "儲值失敗",
        },
    }