import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 多模版全部可配置项
const Templates = [
  {
    path: '/home',
    children: [
      {
        path: 'index',
        components: {
          HomeNavbar : {
            a: () => import('../components/HomeNavbar/TempA.vue'),
            b: () => import('../components/HomeNavbar/TempB.vue'),
            c: () => import('../components/HomeNavbar/TempC.vue'),
          },
          HomeBank : {
            a: () => import('../components/HomeBank/TempA.vue'),
            b: () => import('../components/HomeBank/TempB.vue'),
            c: () => import('../components/HomeBank/TempC.vue'),
          },
          MessageLogo: {
            a: () => import('../components/MessageLogo/TempA.vue'),
            b: () => import('../components/MessageLogo/TempB.vue')
          }
        },
      },
    ]
  },
  {
    path: '/recharge-vi',
    children: [{
      path: 'index',
      components: {
        MessageLogo: {
          a: () => import('../components/MessageLogo/TempA.vue'),
          b: () => import('../components/MessageLogo/TempB.vue')
        }
      }
    }]
  },
  {
    path: '/recharge',
    children: [{
      path: 'index',
      components: {
        MessageLogo: {
          a: () => import('../components/MessageLogo/TempA.vue'),
          b: () => import('../components/MessageLogo/TempB.vue')
        }
      }
    }]
  }
]
// 多模版默认配置
const templateDefault = [
  {
    path: '/home',
    children: [
      {
        path: 'index',
        components: {
          HomeNavbar: 'a',
          HomeBank: 'a',
          MessageLogo: 'a'
        },
      },
    ],
  },
  {
    path: '/recharge-vi',
    children: [{
      path: 'index',
      components: {
        MessageLogo: 'a'
      }
    }]
  },
  {
    path: '/recharge',
    children: [{
      path: 'index',
      components: {
        MessageLogo: 'a'
      }
    }]
  }
]
const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../components/Error.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../components/Home.vue'),
    children: [{
      path: 'index',
      alias: '/home',
      components: {
        HomeNavbar: () => null,
        HomeBank: () => null,
        MessageLogo: () => null
      }
    }]
  },
  {
    path: '/usdt',
    name: 'usdt',
    component: () => import('../components/Usdt.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../components/List.vue')
  },
  {
    path: '/bankcard',
    name: 'bankcard',
    component: () => import('../components/Bankcard.vue')
  },
  {
    path: '/recharge-vi',
    name: 'bankcard',
    component: () => import('../components/Recharge_vi.vue'),
    children: [{
      path: 'index',
      alias: '/recharge-vi',
      components: {
        MessageLogo: () => null
      }
    }]
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../components/Recharge.vue'),
    children: [{
      path: 'index',
      alias: '/recharge',
      components: {
        MessageLogo: () => null
      }
    }]
  },
  {
    path: '/rechargeDetails',
    name: 'rechargeDetails',
    component: () => import('../components/Recharge_details.vue')
  }
]
// GLOBAL_CONFIG.templates的来源可以与环境变量相关或者从后端获取
const GLOBAL_CONFIG = process.env.VUE_APP_TEMP_CCONFIG ? JSON.parse(process.env.VUE_APP_TEMP_CCONFIG) : null
const tempConfig = GLOBAL_CONFIG || templateDefault
const EmptyComponent = function () {
  return null
}
// 递归设置router中components或component
function cookRouter(tempConfig, Templates, routes) {
  tempConfig.forEach((configItem) => {
    const temp = Templates.find(item => item.path === configItem.path)
    const routerItem = routes.find(item => item.path === configItem.path)
    if (configItem?.components) {
      const keys = Object.keys(configItem.components)
      keys.forEach((key) => {
        if (!routerItem || !routerItem.components || !temp || !temp.components || !configItem.components) {
          return new Error('设置有错误')
        }
        routerItem.components[key] = temp.components[key][configItem.components[key]] || EmptyComponent
      })
    } else if (configItem?.component) {
      if (!temp || !temp.component || !routerItem) {
        return new Error('设置有错误')
      }
      routerItem.component = temp.component[configItem.component] || EmptyComponent
    }
    if (configItem?.children) {
      if (!temp?.children || !routerItem || !routerItem.children) {
        return new Error('设置有错误')
      }
      cookRouter(configItem.children, temp.children, routerItem.children)
    }
  })
}
cookRouter(tempConfig, Templates, routes)
console.log(routes)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    // 需要登录验证的路由
    if (isAuthenticated) {
      // 用户已登录，允许访问
      next();
    } else {
      // 用户未登录，跳转到登录页
      //next('/login');
    }
  } else {
    // 不需要登录验证的路由，直接允许访问
    next();
  }
});
export default router
