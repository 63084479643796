export
default {
        common:
        {
            langYn:
            'Tiếng Việt',
            langEn: 'English',
            langJa: '日本語',
            langFt: '繁體中文',
            langKr: '한국인'

        },

        home: {
            h1: "Ngân hàng tiền điện tử số",
            h1B: "Ngân hàng tiền điện tử số",
            h2: "Mời bạn bè",
            h3: "Số tài khoản ngân hàng",
            h4: "Tham gia tiết kiệm",
            h5: " Lựa chọn đầu tư",
            h6: "Nhóm người dùng",
            h7: "Tài khoản",
            h8: "Tài khoản",
            h9: "nút hợp lệ",
            h10: "Người tham gia",
            h11: "lợi nhuận",
            h12: "Danh sách lợi nhuận",
            h13: "Địa chỉ",
            h14: "số",
            h15: "Câu hỏi thường gặp",
            h16: "Làm cách nào để tham gia?",
            h17: "Kính gửi người dùng, nếu bạn muốn tham gia tiết kiệm ví USDT, bạn cần thực hiện nghiêm túc các quyết định của nền tảng trên cơ sở tuân thủ mã người dùng của nền tảng và chấp nhận sự giám sát của bộ phận rủi ro của nền tảng. Sau khi mở tài khoản thành công , bạn sẽ tự động được cấp quyền gửi tiền. Chào mừng bạn. Cảm ơn bạn đã tham gia.",
            h18: "Làm cách nào để rút tiền?",
            h19: "Tiền thu nhập bạn tạo ra hàng ngày sẽ tự động được cộng vào số dư tài khoản của bạn. Sau khi rút USDT thành công, nó sẽ tự động được cộng vào tài khoản ví USDT của bạn. Để rút tiền Đồng Việt Nam, vui lòng liên hệ với trung tâm dịch vụ khách hàng trực tuyến. Cảm ơn bạn vì sự tham gia và hỗ trợ của bạn.",
            h20: "Làm cách nào để tính thu nhập?",
            h21: "Khi bạn tham gia thành công, hợp đồng tiết kiệm sẽ bắt đầu và thu nhập sẽ bắt đầu được tính.",
            h22: "Thu nhập tiết kiệm đến từ đâu?",
            h23: "Khi người dùng tham gia tiết kiệm, hợp đồng tiết kiệm có hiệu lực và người dùng có thể nhận được 4 lần thanh toán lãi mỗi ngày vào các lúc 0:00, 6:00, 12:00 và 18:00. Sau khi hợp đồng tiết kiệm diễn ra có hiệu lực, tiền của người dùng đang ở chế độ lưu ký và tiền được an toàn Nền tảng chịu trách nhiệm. Để đảm bảo an toàn cho tiền tài khoản người dùng, vui lòng tuân thủ nghiêm ngặt các quy tắc của nền tảng và liên hệ kịp thời với trung tâm dịch vụ khách hàng trực tuyến nếu bạn gặp bất kỳ vấn đề nào . Cảm ơn.",
            h24: "Tiền thưởng là bao nhiêu?",
            h25: "Bạn có thể chia sẻ nền tảng của tôi với bạn bè của mình. Nếu anh ấy tham gia, bạn có thể nhận được 20% số tiền gửi đầu tiên của anh ấy dưới dạng tiền thưởng giới thiệu và bạn luôn có thể nhận được 10% thu nhập của anh ấy dưới dạng hoa hồng giới thiệu. Công ty chia sẻ của bạn The liên kết nằm ở góc trên bên phải của trang chủ.",
            h26: "Tài khoản",
            h27: "Tổng thu nhập",
            h28: "Thu nhập của ngày hôm nay",
            h29: "Thu nhập quản lý tài chính",
            h30: "Rút tiền",
            h31: "bản ghi",
            h32: "Số dư khả dụng",
            h33: "Số tiền rút",
            h34: "Vui lòng nhập số tiền rút",
            h35: "tất cả",
            h36: "Trích xuất",
            h37: "Báo cáo kiểm toán",
            h38: "Chúng tôi có báo cáo kiểm tra bảo mật",
            h39: "Đối tác",
            h40: "Đối tác của chúng tôi",
            h41: "Xem số thẻ ngân hàng",
            h42: "Trang trước",
            h43: "Trang tiếp theo",
            h44: "Đã là trang đầu tiên",
            h45: "Vui lòng nhập số tiền rút",
            h46: "Số dư của bạn không đủ",
            h47: "Đã là trang cuối cùng",
            h48: "thu nhập",
            h49: "Chi tiêu",
            h50: 'Hoạt động này quá thường xuyên, vui lòng thử lại sau!',
            h51: 'Báo cáo kiểm toán',
            h52: 'Gửi tiền lãi suất',
            h53: 'Giao dịch đơn hàng',
            h54: 'Thưởng đăng ký',
            h55: 'Thưởng giới thiệu',
            h56: 'Rút tiền',
            h57: 'Nạp tiền',
            h58: 'Thượng Phần',
            h59: 'Điểm thấp hơn',
            h60: 'Gửi USDT bằng tiền điện tử miễn phí',
            h61: 'Để cảm ơn tất cả người dùng đã ủng hộ MetaMask Bank Để việc tiết kiệm thuận tiện hơn cho người dùng Tiết kiệm bằng tiền điện tử (USDT ETH BTC) và được giảm giá cho khoản tiết kiệm của bạn Gửi hơn 1.000 USDT để nhận thêm 30 USDT tiền thưởng Gửi hơn 5.000 USDT để nhận tiền thưởng 200 USDT Nếu bạn gửi hơn 10.000 USDT mỗi lần, bạn sẽ nhận được tiền thưởng 5% số tiền gửi.(Bạn cũng có thể nhận được lợi ích tương tự khi rút tiền bằng ví tiền điện tử)'
        },
        bankcard: {
            b1: "Tài khoản ngân hàng",
            b2: "Tên ngân hàng",
            b3: "Xin vui lòng nhập tên ngân hàng",
            b4: "Chi nhánh ngân hàng",
            b5: "Xin vui lòng nhập chi nhánh ngân hàng",
            b6: "Số nhánh",
            b7: "Xin vui lòng nhập số chi nhánh",
            b8: "Số tài khoản",
            b9: "Xin vui lòng nhập số tài khoản của bạn",
            b10: "Tên tài khoản (hiragana)",
            b11: "Xin vui lòng nhập tên tài khoản của bạn",
            b12: "Tên tài khoản",
            b13: "Xin vui lòng nhập tên tài khoản",
            b14: "Thiết Lập",
            b15:'Số điện thoại'

        },
        usdt: {
            u1: 'Đơn hàng của tôi',
            u2: 'Mua',
            u3: 'Vui lòng chọn thời gian hợp đồng:',
            u4: 'giá mua：',
            u5: 'Vui lòng nhập số lượng mua',
            u6: 'mua thêm',
            u7: 'mua ít hơn',
            u8: 'Hoạt động thành công',
            u9:'ít nhất'
            

        },
        order: {
            o0: 'Đơn hàng của tôi',
            o1: 'số thứ tự',
            o2: 'Kiểu đơn hàng',
            o3: 'mua số lượng',
            o4: 'số lượng bán',
            o5: 'Số tiền thanh toán',
            o6: 'thời gian hợp đồng',
            o7: 'Tình trạng đặt hàng',
            o8: 'tình trạng giải quyết',
            o9: 'thời gian giải quyết',
            o10: 'thời gian phục vụ',
            o11: 'mua thêm',
            o12: 'mua ít hơn',
            o13: 'đã bán',
            o14: 'lợi nhuận',
            o15: 'sự mất mát',
            o16: 'Trả',
            o17: 'Bằng Giá'
        },
        recharge: {

            r1: 'giá trị được lưu trữ',
            r2: 'Số lượng giá trị được lưu trữ',
            r3: 'Loại giá trị được lưu trữ',
            r4: 'Hãy chọn',
            r5: 'địa chỉ tiền gửi',
            r6: 'Chuyển số lệnh giao dịch',
            r7: 'Tôi đã chuyển khoản',
            r8: 'đang được xem xét',
            r9: 'Đầu vào sai',
            r10:'Xin vui lòng chọn phương thức thanh toán',
            r11:"Số tiền nạp tối thiểu là",
            r12:"Số tiền nạp tối đa là",
            r13: "Vui lòng nhập số tiền",
            r14: "Tải lên hình ảnh thanh toán",
            r15: "Vui lòng tải lên ảnh chụp màn hình thanh toán",
            r16: "gửi thành công",
        },
        rechargeDetails: {
            r1: "Bản ghi giá trị được lưu trữ",
            r2: "giá trị lưu trữ",
            r3: "đang xem xét",
            r4: "Nạp tiền thành công",
            r5: "Không thể lưu giá trị",
        },

    }