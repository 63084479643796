export
default {
        common:
        {
            langYn:
            'Tiếng Việt',
            langEn: 'English',
            langJa: '日本語',
            langFt: '繁體中文',
            langKr: '한국인'

        },
        home: {
            h1: "디지털 암호화폐 은행",
            h1B: "디지털 암호화폐 은행",
            h2: "친구 초대",
            h3: "은행계좌번호",
            h4: "저축에 참여하세요",
            h5: "선택된 금융",
            h6: "사용자 ",
            h7: "계정",
            h8: "계정",
            h9: "유효한 노드",
            h10: "참가자",
            h11: "수익",
            h12: "수익 기록",
            h13: "주소",
            h14: "번호",
            h15: "FAQ",
            h16: "어떻게 가입하나요",
            h17: "사용자 여러분, USDT 지갑 저축에 참여하려면 플랫폼 사용자 코드를 준수한다는 전제 하에 플랫폼의 결정을 엄격하게 이행해야 하며 플랫폼 위험 부서의 감독을 수락해야 합니다. 계정을 성공적으로 개설한 후 , 자동으로 입금 권한이 부여됩니다. 환영합니다. 참여해주셔서 감사합니다.",
            h18: "돈을 출금하려면 어떻게 해야 하나요?",
            h19: " 매일 발생하는 수익은 계정 잔액에 자동으로 입금됩니다. USDT 출금이 성공한 후 USDT 지갑 계정에 자동으로 추가됩니다. 베트남 동 출금의 경우 온라인 고객 서비스 센터에 문의하세요. 감사합니다. 많은 참여와 응원 부탁드립니다.",
            h20: "수익을 어떻게 계산하나요?",
            h21: "가입에 성공하면 저축계약이 시작되고 수익이 계산되기 시작합니다.	",
            h22: "저축소득은 어디서 나오나요?",
            h23: "사용자가 저축에 참여하면 저축 계약이 적용되고 사용자는 매일 0:00, 6:00, 12:00, 18:00에 4번의 이자 정산을 받을 수 있습니다. 저축 계약이 완료된 후 사용자의 자금은 보관 상태에 있으며 자금은 안전합니다.플랫폼이 책임을 집니다.사용자 계정 자금의 안전을 보장하기 위해 플랫폼 규칙을 엄격히 준수하고 문제가 발생할 경우 즉시 온라인 고객 서비스 센터에 문의하십시오. . 감사합니다.",
            h24: "보너스는 얼마인가요?",
            h25: "친구와 제 플랫폼을 공유할 수 있습니다.친구분 가입시 첫 예금금액의 20%를 추천 커미션을 받을수 있고 수익의10%를 추천 커미션으로 받아갈수있습니다  공유 링크는 화면 오른쪽 상단에 있습니다",
            h26: "계정",
            h27: "총 수입",
            h28: "오늘의 수입",
            h29: " 재무관리소득",
            h30: "현금 인출",
            h31: "기록",
            h32: "사용 가능한 잔액",
            h33: "출금 금액",
            h34: "출금금액을 입력해주세요",
            h35: "모두",
            h36: "추출",
            h37: "감사 보고서",
            h38: "보안 감사 보고서가 있습니다",
            h39: "파트너",
            h40: "우리의 파트너",
            h41: "은행 카드 번호 보기",
            h42: "이전 페이지",
            h43: "다음 페이지",
            h44: "이미 첫 번째 페이지입니다",
            h45: "출금금액을 입력해주세요",
            h46: "잔고가 부족합니다",
            h47: "이미 마지막 페이지입니다",
            h48: "소득",
            h49: "지출",
            h50: '과도한 조작입니다. 나중에 다시 시도해 주세요!',
            h51: '감사 보고서',
            h52: '스테이킹',
            h53: '주문 거래',
            h54: '등록 보상',
            h55: '추천 보상',
            h56: '철회',
            h57: '입금',
            h58: '상펀',
            h59: '낮은 점',
            h60: 'USDT를 암호화폐로 무료로 보내기',
            h61: 'MetaMask Bank를 지원해 주신 많은 사용자분들께 감사드립니다. 사용자가 보다 쉽게 ​​저장할 수 있도록 암호화폐(USDT ETH BTC)로 전송 및 저장 시 저축 보너스를 받으세요.1,000 USDT 이상 입금하고 추가 30 USDT 보상을 받으세요 5,000 USDT 이상 입금하고 200 USDT 보너스 받기 10,000 USDT 이상 저축할 때마다 5% 저축 보너스를 받으세요 (암호화폐 지갑을 이용한 출금시에도 동일한 혜택을 받으실 수 있습니다)'
        },
        bankcard: {

            b1: "은행 계좌",
            b2: "은행 이름",
            b3: "은행명을 입력해주세요",
            b4: "은행 지점",
            b5: "은행 지점을 입력해주세요",
            b6: "지점 번호",
            b7: "지점번호를 입력해주세요",
            b8: "계정",
            b9: "계좌번호를 입력해주세요",

            b11: "계정 이름을 입력하세요",
            b12: "계정 이름",
            b13: "계정 이름을 입력해주세요",
            b14: "바인딩"

        },
        usdt: {
            u1: "내 주문",
            u2: "구매",
            u3: "계약 시간을 선택하세요",
            u4: "구매 가격",
            u5: "구매금액을 입력해주세요",
            u6: "더 구매",
            u7: "덜 구매하세요",
            u8: "작업 성공",
            u9:" 최소"
           

        },
        order: {
            o0: "내 주문",
            o1: "주문 번호",
            o2: "주문 유형",
            o3: "구매 금액",
            o4: "판매금액",
            o5: "결제 금액",
            o6: "계약 시간",
            o7: "주문 상태",
            o8: "정산현황",
            o9: "정착 시간",
            o10: "주문 시간",
            o11: "더 구매",
            o12: "적게 구매",
            o13: "판매됨",
            o14: '이익',
            o15: " 손실",
            o16: '유료',
            o17: ' 손익분기점'
        },
        recharge: {

            r1: '저장된 값',
            r2: '저장된 가치 금액',
            r3: '저장된 값 유형',
            r4: '선택하세요',
            r5: '입금 주소',
            r6: '이체 거래 주문 번호',
            r7: '이전했습니다',
            r8: '검토중',
            r9: '잘못된 입력',
            r10:'결제 방식을 선택하세요',
            r11:"최소 충전량은",
            r12:"최대 충전량은 다음과 같습니다.",
            r13: "금액을 입력해주세요.",
            r14: "결제 스크린샷 업로드",
            r15: "결제 스크린샷을 업로드해주세요.",
            r16: "성공적으로 제출되었습니다",
        },
        rechargeDetails: {

            r1: "저장된 가치 기록",
            r2: "저장값",
            r3: "검토중",
            r4: "충전 성공",
            r5: "값을 저장하지 못했습니다",
        },

    }